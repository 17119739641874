<template>
  <AbstractFilter
    :on-filter="filterQuotes"
    :on-filter-reset="resetFilter"
    :button-required-permissions="requiredPermissions"
    create-new-route-name="quote_new"
    filter-panel-class="filter-panel-quote"
  >
    <Select
      v-model="filter.site"
      :options="sites"
      :label="$t('quote.filter.site')"
      id="filter_site"
    />
    <Input
      v-model="filter.title"
      id="filter_title"
      :label="$t('quote.filter.title')"
    />
    <Input
      v-model="filter.bodyText"
      id="filter_bodyText"
      :label="$t('quote.filter.bodytext')"
    />
    <Input
      v-model="filter.id"
      id="filter_id"
      :label="$t('quote.filter.id')"
    />
  </AbstractFilter>
</template>

<script>
import Input from '@/components/form/inputs/Input'
import Select from '@/components/form/select/Select'
import QuoteFilterModel from '@/model/QuoteFilter'
import AbstractFilter from '@/components/filter/AbstractFilter'
import PermissionService from '@/services/PermissionService'
import { MODULE_MODULES_QUOTE } from '@/model/ValueObject/UserPermissionModules'

export default {
  name: 'QuoteFilter',
  data () {
    return {
      filter: this._.cloneDeep(QuoteFilterModel),
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.QUOTE_PERMISSIONS
    }
  },
  computed: {
    sites () {
      return this.$store.getters['site/enabledSites'](MODULE_MODULES_QUOTE)
    }
  },
  components: {
    AbstractFilter,
    Input,
    Select
  },
  methods: {
    filterQuotes () {
      this.$store.commit('quote/setPage', 1)
      this.$store.commit('quote/setFilter', this.filter)
      this.$store.dispatch('quote/fetch')
    },
    resetFilter () {
      this.filter = this._.cloneDeep(QuoteFilterModel)
      this.$store.commit('quote/setFilter', this.filter)
      this.$store.dispatch('quote/fetch')
    }
  },
  beforeMount () {
    this.filter = this.$store.getters['quote/filter']
  }
}
</script>

<style lang="scss">
  .filter-panel-quote {
    @include bp(7) {
      grid-template-columns: 1fr 1fr;
    }
    @include bp(12) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }
</style>
